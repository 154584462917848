<template>
  <div class="container_con">
    <el-card>
      <div class="search">
          <div>
              <el-button type="primary" @click="$router.push('/addPersonnel')" v-if="have_auth('/personnel/add')">+ 新增</el-button>
          </div>
          <div>
              <el-input icon="el-icon-search" v-model="queryParams.title" @keyup.enter.native="handleQuery" placeholder="请输入搜索内容" clearable
                  style="width:300px;margin-right:10px"></el-input>
              <el-button type="primary" @click="handleQuery()">搜索</el-button>
              <el-button @click="resetQuery()">重置</el-button>
          </div>
      </div>
      
      <div style="margin-top:20px">
        <el-table :data="list"  v-loading="loading" style="width: 100%">
          <el-table-column label="照片" width="80">
            <template v-slot="scope">
              <img class="avatr" v-if="scope.row.photo" :src="$http+scope.row.photo" alt="" />
              <img class="avatr" v-if="!scope.row.photo" src="../../assets/avatar.jpg" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="full_name" label="姓名" width="100"/>
          <el-table-column prop="mobile" label="手机号" width="150" />
          <el-table-column prop="department_name" label="部门" width="150" :show-overflow-tooltip="true"/>
          <el-table-column prop="position_name" label="职位" />
          <el-table-column prop="address" label="住址" />
          <el-table-column prop="id_card" label="身份证" width="180" />
          <el-table-column prop="username" label="登录用户名" width="180" />
          <el-table-column prop="sort" label="排序"/>
          <el-table-column fixed="right" label="操作" width="150">
            <template v-slot="scope">
              <el-button
                @click="handleDetail(scope.row)"
                type="text"
                size="small"  v-if="have_auth('/personnel/view')" 
                >查看</el-button
              >
              <el-button type="text" size="small" @click="handleUpdate(scope.row)"  v-if="have_auth('/personnel/edit')">编辑</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row)"  v-if="have_auth('/personnel/del')">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
</template>

<script>
export default {
  name: "personnel",
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          title: ''
      },
      total:0,
      list:[]
    };
  },
  created() {
      this.queryParams=window[this.$route.path] || {
        page: 1,
        size: 10,
        title: ''
      }
      this.getList();
  },
  watch: {
    $route(to,form) {
        window[form.path]=this.queryParams
    },
  },
  methods: {
    resetQuery(){
          this.queryParams= {
              page: 1,
              size: 10,
              title: ''
          }
          this.getList()
    },
    // 查看详情
    handleDetail(row){
        window.personnelDetail=row;
        this.$router.push('/personnelDetail')
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/addPersonnel?id='+row.id)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const _this=this;
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          
          _this.$httpGet("/backend/sys.Admin/del", {id:ids}).then((res) => {
              if (res.status == 200) {
                  _this.getList();
                  _this.$message.success(res.message);
              } else {
                  _this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
        })
    },
    /** 查询列表 */
    getList() {
        this.loading = true;
        this.$httpGet("/backend/sys.Admin/index", this.queryParams).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    }
  },
};
</script>

<style lang="scss" scoped>
.avatr {
  height: 48px;width: 48px; border-radius: 50%; object-fit: cover;
}
</style>
